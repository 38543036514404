(function(){

	var ativadorSombra = $('[data-sombra="true"]');

	var alvoClasse = $('body');

	var alvoClasseClassesToggle = alvoClasse.attr('data-toggle');

	ativadorSombra.bind('click',function(){
		alvoClasse.addClass('sombra');
	});

	var desligar = $('[data-desligador="true"]');

	desligar.bind('click',function(){
		alvoClasse.removeClass(alvoClasseClassesToggle);
	});

	var menuToggle = $('[data-menu-active="true"]');

	menuToggle.bind('click',function(){
		alvoClasse.addClass('menu-active');
	});

	var formBuscasMobile = $('[data-pesquisas-mobile="true"]');
	
	formBuscasMobile.bind('click',function(){
		alvoClasse.addClass('form-buscas-ativo');
	});
	
})();